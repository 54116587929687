<template>
  <div class="card p-3 shadow-sm">
    <div class="row">
      <div class="col-12">
        <h4>Vessels</h4>
      </div>
    </div>
    <div v-if="loading || selectedUser?.user_vessels === undefined" class="w-100">
      <app-loading/>
    </div>
    <form v-else @submit.prevent="requestSetVessels">
      <div class="form-row justify-content-between align-items-center mb-2">
        <div class="form-group col-lg-auto form-inline mb-0">
        </div>
        <div class="col-lg-auto">
          <button v-show="!toEdit" type="button" class="e-btn e-btn-orange px-4" @click="edit">Edit</button>
          <button v-show="toEdit" type="submit" class="e-btn e-btn-green px-3 mr-2" :disabled="hasChanges === false" :class="[hasChanges === false ? 'e-btn-green-disabled' : '']">Submit</button>
          <button v-show="toEdit" type="button" class="e-btn e-btn-outlined-red px-3" @click="cancelEdit">Cancel</button>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 my-2" v-for="mngmntVessel in mngmntOfficeVesselsKeys">
          <div class="card card-body e-bg-blue-light-9 border-0 shadow-sm">
            <h6 class="h6 text-uppercase">{{ mngmntVessel.name }} </h6>
            <div class="form-check form-check-inline align-items-center d-flex pb-1">
              <input class="form-check-input expand-25-percent mr-2" type="checkbox"
                     :disabled="toEdit === false"
                     :id="mngmntVessel.name + '_all'"
                     :ref="mngmntVessel.name + '_all'"
                     :checked="mngmntVessel.select_all"
                     @click="setSelectAllPerManagement(mngmntVessel)"
              />
              <label class="form-check-label cursor-pointer text-uppercase no-select" :for="mngmntVessel.name + '_all'">SELECT ALL</label>
            </div>

            <div class="form-check form-check-inline align-items-center d-flex pb-1 pl-3" v-for="(vessel, index) in mngmntVessel.vessels">
              <input class="form-check-input expand-25-percent mr-2" type="checkbox"
                     :disabled="toEdit === false"
                     :id="vessel.name+'.'+index"
                     :ref="vessel.name+'.'+index"
                     v-model="selectedVesselIds"
                     :value="vessel.id"
                     @change="setVesselPerManagement(mngmntVessel, vessel.id)"
              >
              <label class="form-check-label cursor-pointer text-uppercase no-select" :for="vessel.name+'.'+index">{{ vessel.name }}</label>
            </div>
          </div>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
import TreePermissionCheckbox from "@/components/settings/user/TreePermissionCheckbox.vue";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import {UserService} from "@/services/UserService";
import {permissionMixin} from "@/mixins/permissionMixins";
import AppLoading from "@/components/elements/AppLoading.vue";
import {DataService} from "@/services/DataService";
import vessel from "@/views/Vessel.vue";

export default {
  name: "UserVessels",
  mixins: [permissionMixin],
  data () {
    return {
      toEdit: false,
      loading : true,
      current_user_vessels : [],
      selectedVesselIds : []
    }
  },
  methods: {
    ...mapActions(['getManagementVessels', 'defineSelectedUser']),
    requestSetVessels() {
      UserService.updateUserVessels({
        user_id : this.selectedUser.id,
        vessel_ids : this.selectedVesselIds
      }).then(response => {
        this.toEdit = false
        this.setUser();
        this.current_user_vessels = [];
        AlertService.successAlert('User vessels has been updated', 'UPDATE USER VESSELS')
      })
        .catch(error => {
          AlertService.errorAlert(error.response.data.message, 'UPDATE USER VESSELS')
        });
    },
    async setUser() {
      await this.defineSelectedUser(this.$route.params.id)
    },
    setCurrentUserVessels() {
      this.current_user_vessels = [];
      this.selectedUser.user_vessels.forEach(item => {
        this.current_user_vessels.push(item);
      });
    },
    edit() {
      this.toEdit = true;
      this.setCurrentUserVessels();
    },
    async cancelEdit() {
      if (this.hasChanges === true) {
        if (await AlertService.cancelAlert()) {
          this.toEdit = false;
          this.selectedVesselIds = this.current_user_vessels.map(vessel => vessel.id);
          this.current_user_vessels = [];
        }
      } else {
        this.toEdit = false;
        this.selectedVesselIds = this.current_user_vessels.map(vessel => vessel.id);
        this.current_user_vessels = [];
      }
    },
    setSelectAllPerManagement(managementVessels) {
      managementVessels.select_all = !managementVessels.select_all;

      managementVessels.vessels.forEach(vessel => {

        if (managementVessels.select_all === true) {
          if (this.selectedVesselIds.includes(vessel.id) === false) {
            this.selectedVesselIds.push(vessel.id);
          }
        } else {
          this.selectedVesselIds = this.selectedVesselIds.filter(vesselId => vesselId !== vessel.id);
        }
      });
    },
    checkSelectAllPerManagementOffice(managementOffice) {
      const vessels = managementOffice.vessels;
      const vesselsCount = vessels.length;
      let userVesselsCount = 0;
      for (let counter = 0; counter < vesselsCount; counter++) {
        const vessel = vessels[counter];
        if (this.selectedVesselIds.includes(vessel.id) === true) {
          userVesselsCount++;
        }
      }
      if (userVesselsCount === 0 || userVesselsCount < vesselsCount) {
        managementOffice.select_all = false;
      } else if (userVesselsCount === vesselsCount) {
        managementOffice.select_all = true;
      }

      return managementOffice;
    },
    setSelectAllForAllManagement() {
      this.mngmntOfficeVesselsKeys.forEach(managementOffice => {
        managementOffice = this.checkSelectAllPerManagementOffice(managementOffice);
      });
    },
    setVesselPerManagement(managementOffice, vesselId) {
      managementOffice = this.checkSelectAllPerManagementOffice(managementOffice);
    }
  },
  watch : {
    storeSelectedUser(newValue) {
      if (newValue !== null) {
        this.selectedVesselIds = newValue.user_vessels.map(vessel => vessel.id);
        this.setSelectAllForAllManagement()
      }
    }
  },
  components: {
    AppLoading,
    TreePermissionCheckbox
  },
  computed: {
    ...mapGetters(['selectedUser', 'mngmntOfficeVesselsKeys']),
    storeSelectedUser() {
      return this.selectedUser;
    },
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      const originalData = {
        vessel_ids : this.current_user_vessels.map(vessel => vessel.id)
      }

      const editedData = {
        vessel_ids : this.selectedVesselIds
      }

      return DataService.checkIfChanged(originalData, editedData);
    }
  },
  async mounted() {
    await this.getManagementVessels();
    if (Object.keys(this.selectedUser).length > 0) {
      this.selectedVesselIds = this.selectedUser.user_vessels.map(vessel => vessel.id);
      this.setSelectAllForAllManagement()
    }
    this.loading = false;
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges() === true) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>
<style scoped>

</style>
