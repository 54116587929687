<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="resetPassword">
      <div class="row px-2 align-items-center">
        <div class="col-6">
          <h6 class="font-weight-bold text-left">Reset Password</h6>
        </div>
        <div class="col-6 text-right">
          <button class="e-btn e-btn-green e-btn" type="submit">Save</button>
        </div>
      </div>
      <div class="form-group row text-left pl-2">
        <label for="currentPassword" class="col-sm-2 col-form-label">New Password</label>
        <div class="col-sm-4 text-left">
          <input type="password" class="form-control form-control-sm" v-model="fields.new_password" id="currentPassword" autocomplete="new-password"
                 placeholder="New Password">
          <span class="font-weight-bolder text-danger"
                v-if="errors.new_password">{{ errors.new_password.join(',') }}</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {AlertService} from "@/services/AlertService";
import {UserService} from "@/services/UserService";

export default {
  name: "ResetPassword",
  data() {
    return {
      fields: {
        new_password: null,
      },
      errors: {},
    }
  },
  methods: {
    async resetPassword() {
      if (await AlertService.confirmUpdateAlert('Do you want to Reset User\'s Password?')) {
        this.fields.user_id=this.$route.params.id
        const response = await UserService.resetPassword(this.fields);
        if(response.hasOwnProperty('success') && response.success){
          AlertService.successAlert('Password has been Reset Successfully!', 'UPDATE PASSWORD')
          this.resetFields();
        }
        else {
          if(response.response.status === 422){
            this.errors=response.response.data.errors;
          }
          else{
            this.errors=null
          }
        }
      }
    },
    async resetFields(){
      this.fields={
        new_password:null,
      }
      this.errors={}
    },
  }
}
</script>

<style scoped>

</style>
