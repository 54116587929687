<template>
  <div class="card p-3 shadow-sm">
      <div class="row">
        <div class="col-12">
            <h4>Roles and Permissions</h4>
        </div>
      </div>
    <form @submit.prevent="updateUserRolePermissions">
      <div class="form-row justify-content-between align-items-center mb-2">
        <div class="form-group col-lg-auto form-inline mb-0">
          <label for="userRoleSelection" class="font-weight-bold mr-3">Role Name: </label>
          <select name="userRoleSelection" id="userRoleSelection" class="form-control form-control-sm px-3"
                  :disabled="!toEdit"
                  v-model="newRole"
                  @change="selectRole(newRole)"
          >
            <option :value="null" disabled selected hidden>--- CHOOSE ROLE ---</option>
            <option v-if="isElite()" value="super-user">Administrator (ELITE) </option>
            <option value="director">Director</option>
            <option value="general-manager">General Manager</option>
            <option value="manager">Manager</option>
            <option value="superintendent">Superintendent</option>
            <option value="instructor">Instructor</option>
            <option value="vessel" disabled>Vessel</option>
          </select>
        </div>
        <div class="col-lg-auto">
          <button v-show="!toEdit" type="button" class="e-btn e-btn-orange px-4" @click="editUserPermissions">Edit</button>
          <button v-show="toEdit" type="submit" class="e-btn e-btn-green px-3 mr-2">Submit</button>
          <button v-show="toEdit" type="button" class="e-btn e-btn-outlined-red px-3" @click="cancelEditUserPermission">Cancel</button>
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-4 my-2" v-for="(permission, index) in staticPermissionKeys" :key="index">
          <div class="card card-body e-bg-blue-light-9 border-0 shadow-sm">
            <h6 class="h6">{{ permission._description }} <span class="e-text-black-light-6">Permission</span></h6>
              <TreePermissionCheckbox :node="permission"
                                      :permission-list="permissionPerRole"
                                      :disable-checkbox="!toEdit"
                                      @permissionChecked="setPermission"
                                      :depth="index"/>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TreePermissionCheckbox from "@/components/settings/user/TreePermissionCheckbox.vue";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import {UserService} from "@/services/UserService";
import {permissionMixin} from "@/mixins/permissionMixins";

export default {
  name: "RoleAndPermission",
  mixins: [permissionMixin],
  data () {
    return {
      newRole: null,
      toEdit: false,
      permissionPerRole: [],
      beforeEditPermissions: [],
      // staticPermissionKeys: [],
    }
  },
  methods: {
    ...mapActions(['defineSelectedUser','getPermissionKeys', 'getStaticPermissionKeys']),
    async initializeUserRole(){
      await this.getStaticPermissionKeys()
      await this.getPermissionKeys()
      await this.defineSelectedUser(this.$route.params.id)
      this.permissionPerRole = Object.assign(this.userPermissions)
      if (this.selectedUser.roles_permissions.roles.length){
        this.newRole = this.selectedUser.roles_permissions.roles[0]
      } else {
        this.newRole = null
      }
    },
    editUserPermissions() {
      this.toEdit = true
      this.beforeEditPermissions = JSON.parse(JSON.stringify(this.permissionPerRole))
    },
    async cancelEditUserPermission() {
      if (await AlertService.confirmDeleteAlert('CANCEL CHANGES', 'Are you sure you want to cancel your changes? ' +
        'This will not save your changes', 'Yes, I confirm')) {
        this.permissionPerRole = Object.assign(this.beforeEditPermissions)
        this.toEdit = false
      }
    },
    selectRole(roleName){
      const filteredObj = {};
      for (const key in this.permissionKeys) {
        if (roleName.includes(key)) {
          filteredObj[key] = this.permissionKeys[key]
          break
        }
      }
      this.permissionPerRole = Object.values(filteredObj)[0]
    },

    setPermission(value){
      if(this.permissionPerRole.includes(value)){
        this.permissionPerRole=this.permissionPerRole.filter(val=>val!=value);
      }
      else {
        this.permissionPerRole.push(value)
      }
      console.log(this.permissionPerRole);
    },
    async updateUserRolePermissions(){
      if (await AlertService.confirmUpdateAlert("THIS WILL LOGOUT ALL THE LOGGED IN SESSIONS OF THE SELECTED USER")){
        await UserService.updateRolePermissions({
          'userId': this.$route.params.id,
          'userRoleSelection': [this.newRole],
          'userPermissionSelection': this.permissionPerRole
        })
        AlertService.successAlert('Updating User Role Permissions Successful', 'UPDATE')
        this.beforeEditPermissions = JSON.parse(JSON.stringify(this.permissionPerRole))
        this.toEdit = false
      } else {
        this.toEdit = true
      }
    }
  },
  components: {
    TreePermissionCheckbox
  },
  computed: {
    ...mapGetters(['permissionKeys', 'selectedUser', 'userPermissions', 'staticPermissionKeys']),
  },
  mounted() {
    this.initializeUserRole()
  }
}
</script>
<style scoped>

</style>
