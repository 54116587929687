<template>
  <div>
    <template v-for="(permission, index) in node">
      <div v-if="typeof permission == 'object'" class="pl-3">
        <p class="mb-0 font-weight-bold indent-back">{{ permission._description }}</p>
        <TreePermissionCheckbox :node="permission"
                                :disable-checkbox="disableCheckbox"
                                :permission-list="permissionList"
                                :depth="depth + '.' + index"
                                @permissionChecked="(item)=>$emit('permissionChecked',item)"
        />
      </div>
      <div class="" v-else-if="index === '_description'"></div>
      <div class="form-check form-check-inline align-items-center d-flex pb-1 pl-3" v-else :title="depth+'.'+index">
        <input class="form-check-input expand-25-percent mr-2" type="checkbox"
               :disabled="disableCheckbox"
               :id="depth+'.'+index"
               :ref="depth+'.'+index"
               :checked="permissionList.includes(depth+'.'+index)"
               :value="depth+'.'+index"
               @change="triggerChecked(depth+'.'+index)"
        >
        <label class="form-check-label" :for="depth+'.'+index">{{ permission }}</label>
      </div>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TreePermissionCheckbox",
  data(){
    return {
      permissionLists:[],
    }
  },
  props: {
    node: Object,
    depth: null,
    disableCheckbox: false,
    permissionList:[],
  },
  methods: {
    ...mapActions(['defineSelectedUser']),
    triggerChecked(value){
      this.$emit('permissionChecked',value);
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  mounted() {
    this.permissionLists=this.permissionList
  }
}
</script>


<style scoped>
.expand-25-percent {
  transform: scale(1.25);
}
.indent-back {
  text-indent: -0.25em;
}
</style>
